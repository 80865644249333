import React, { Component } from 'react';
import { BrowserRouter as Router, Route} from "react-router-dom";
import './App.css';
import IndexPage from './pages/index';
import MetatagsPage from './pages/metatags';
import SitemapsPage from './pages/sitemaps';

class App extends Component {
  render() {
    return (
      <Router>
        <div>
        <Route exact path="/" component={IndexPage} />
          <Route exact path="/metatags" component={MetatagsPage} />
          <Route exact path="/sitemaps" component={SitemapsPage} />
        </div>
    </Router>
    );
  }
}

export default App;
