import React, { Component } from 'react';
import axios from 'axios';

import Header from '../elements/header'

class SitemapsPage extends Component{
    
    state = {
        sitemaps : [],
    };

    componentDidMount(){
        const formdata = new FormData();
        formdata.append("operation", "sitemaps_get");
        axios.post(`https://seo.ewall.com.pk/ajax.php`, formdata)
        .then(res => {
            this.setState({sitemaps: res.data.data});
        });
    }

    ping = (event) =>{
        event.preventDefault();
        event.target.innerHTML = "---";
        const id = event.target.closest("tr").getAttribute("data-id");
        axios.get("http://www.google.com/webmasters/tools/ping?sitemap="+this.state.sitemaps[id]['url'])
        .then(res => {
            
        });
        event.target.innerHTML = "Pinged";
    }
    render(){
        return(
            <div className="">
                <Header/>
                <div className="page-wrapper container">
                    <div class="page-header">
                        <h4>Sitemaps</h4>
                    </div>
                    <table className="table table-bordered">
                        <thead>
                            <th>Sitemap:</th>
                            <th>Items:</th>
                            <th>Url:</th>
                            <th>Actions:</th>
                        </thead>
                        <tbody>
                            {this.state.sitemaps.map(((sitemap, key) =>
                                <tr key={key} data-id={key}>
                                    <td>{sitemap.sitemap}</td>
                                    <td>{sitemap.links}</td>
                                    <td><a href={sitemap.url} target="__blank">{sitemap.url}</a></td>
                                    <td>
                                        <a href="#" className="btn btn-primary btn-sm" onClick={this.ping.bind(this)}>Ping</a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default SitemapsPage;