import React, { Component } from 'react';

import Header from '../elements/header'

class IndexPage extends Component{
    
    render(){
        return(
            <div className="">
                <Header/>
                <div className="page-wrapper container">
                    <div class="page-header">
                        <h4>Home</h4>
                    </div>
                </div>
            </div>
        )
    }
}

export default IndexPage;