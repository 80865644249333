import React, { Component } from 'react';
import axios from 'axios';

import Header from '../elements/header'


class MetatagsPage extends Component{
    constructor(props){
        super(props);
        this.state = {
            title: '',
            description: '',
            keywords: '',
            id: '',
            url: ''
        };
        this.handleChange = this.handleChange.bind(this);
    }

    urlEvent = (event) => {
        if (event.key === 'Enter') {
            this.setState({
                title: '',
                description: '',
                keywords: '',
                id: ''
            });
            const formdata = new FormData();
            formdata.append("operation", "meta_get");
            formdata.append("url", event.target.value);
            axios.post(`https://seo.ewall.com.pk/ajax.php`, formdata)
            .then(res => {
                this.setState({
                    id: res.data.data['id'],
                    title: res.data.data['title'],
                    description: res.data.data['description'],
                    keywords: res.data.data['keywords']
                });
            });
        }
    }
    handleChange = (event) => {
        this.setState({[event.target.getAttribute("name")]: event.target.value});
    }

    saveMeta = () => {
        const formdata = new FormData();
        formdata.append("operation", "meta_save");
        formdata.append("id", this.state.id);
        formdata.append("title", this.state.title);
        formdata.append("keywords", this.state.keywords);
        formdata.append("description", this.state.description);
        formdata.append("url", this.state.url);
        axios.post(`https://seo.ewall.com.pk/ajax.php`, formdata)
        .then(res => {
            window.location.reload();
        });
    }
    
    render(){
        return(
            <div className="">
                <Header/>
                <div className="page-wrapper container">
                    <div class="page-header">
                        <h4>Meta Tags</h4>
                    </div>
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-8">
                            <div className="form-group">
                                <input type="text" name="url" className="form-control" placeholder="Enter page url" onKeyUp={this.urlEvent.bind(this)} onChange={this.handleChange}/>
                            </div>
                        </div>
                    </div>
                    <br/><br/><br/>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <lable>Title:</lable>
                                <textarea name="title" className="form-control" rows="4" value={this.state.title} onChange={this.handleChange}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <lable>Keywords:</lable>
                                <textarea type="text" name="keywords" className="form-control" rows="4" value={this.state.keywords} onChange={this.handleChange}></textarea>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="form-group">
                                <lable>Description:</lable>
                                <textarea type="text" name="description" className="form-control" rows="5" value={this.state.description} onChange={this.handleChange}></textarea>
                            </div>
                        </div>
                        <div className="col-md-12 d-flex justify-content-end">
                            <div className="form-group justify-content-end">
                                <button type="button" className="btn btn-primary" onClick={this.saveMeta}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default MetatagsPage;